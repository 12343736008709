import Moment from "react-moment";
import styled from "styled-components";
import "react-18-image-lightbox/style.css";
import Typewriter from 'typewriter-effect';
import styles from "./HomePage.module.scss";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ScrollToTop from "react-scroll-to-top";
import { useCopyToClipboard } from "react-use";
import Lightbox from "react-18-image-lightbox";
import { Button } from "../../components/Button";
import { Image, Gallery } from "react-grid-gallery";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Group } from "../../components/Layout/Group/Group";
import Countdown, { CountdownRenderProps } from "react-countdown";
import { Buildings, Calendar2Day, Circle, CircleFill, Clock, Copy, Facebook, GeoAlt, Gift, Globe2, Instagram, Twitter } from "react-bootstrap-icons";

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 2,
        slidesToSlide: 2 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};

const StyledHome = styled(Group)`
    width: 100%;
    height: 100%;
    background-size:cover;
    background-image: url("/assets/images/juls-and-jay.jpeg");
`

const StyledCouple = styled(Group)`
    width: 100%;
    height: 100%;

    .gettingMarried {
        text-align: center;
        font-size: 38px;
    }

    .gettingMarriedDate {
        margin-top: -14px;
    }

    .picture {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        background-size:cover;
    }

    .bride-picture {
        background-image: url("/assets/images/juls.jpeg");
    }

    .groom-picture {
        background-image: url("/assets/images/jay.jpeg");
    }
`

const StyledCountDownContainer = styled(Group)`
    width: 100%;
    overflow-y: auto;
    height: 350px;
    background-image: url("/assets/images/juls-and-jay-2.jpg");
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
`

const StyledOurStory = styled(Group)`
    width: 100%;
    height: 100%;
`

const StyledCeremonyCard = styled(Group)`
    background: transparent;

    &:hover {
        background-size:cover;
    }

    &.first {
        &:hover {
            background-image: url("/assets/images/juls-and-jay-001.png");
        }
    }

    &.second {
        &:hover {
            background-image: url("/assets/images/juls-and-jay-002.png");
        }
    }

    &.third {
        &:hover {
            background-image: url("/assets/images/juls-and-jay-003.png");
        }
    }

    &.fourth {
        &:hover {
            background-image: url("/assets/images/juls-and-jay-004.png");
        }
    }

`

const StyledWhenAndWhereCard = styled(Group)`
    .header {
        height: 250px;
        background-size:cover;
        .content {
            visibility: hidden;
            iframe {
                width: 100%;
                height: 250px;
            }
        }
    }

    .first {
        background-image: url("/assets/images/when_and_where_1.png");
    }

    .second {
        background-image: url("/assets/images/when_and_where_2.png");
    }

    .third {
        background-image: url("/assets/images/when_and_where_3.png");
    }

    &:hover {
        .header {
            background-image: unset;
            .content {
                visibility: visible;
            }
        }
    }
`

const StyledFooter = styled(Group)`
    width: 100%;
    height: 100%;
    padding: 60px 0;

    .title {
        font-size: 34px;
        margin-bottom: -16px;
    }
`

const tradDate = "2024-06-14 13:00:00";
const mainDate = "2024-06-15 11:00:00";

export const HomePage = () => {

    const navigate = useNavigate();
    const containerRef = useRef<HTMLDivElement>(null);
    const [activeSection, setActiveSection] = useState<string>('');
    const sectionRefs = useRef<{ id: string; ref: HTMLDivElement }[]>([]);

    useEffect(() => {
        const handleScroll = () => {
            if (!containerRef.current) return;

            const container = containerRef.current;
            const scrollPosition = container.scrollTop;
            let newActiveSection = '';

            for (const { id, ref } of sectionRefs.current) {
                if (ref.offsetTop <= scrollPosition + container.clientHeight / 2) {
                    newActiveSection = id;
                } else {
                    break;
                }
            }

            setActiveSection(newActiveSection);
        };
        if (containerRef.current) {
            containerRef.current.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (containerRef.current) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                containerRef.current.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    const scrollToSection = (sectionId: string) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const sections = [
        { content: <HomeSectionContent />, id: "home", text: "Home" },
        { content: <CoupleSectionContent />, id: "couple", text: "Couple" },
        { content: <OurStorySectionContent />, id: "ourStory", text: "Our Story" },
        { content: <CeremonySectionContent />, id: "ceremony", text: "Ceremony" },
        { content: <MenuContent />, id: "menu", text: "Menu" },
        { content: <WhereAndWhenContent />, id: "whenAndWhere", text: "When & Where" },
        { content: <ColorOfTheDayContent />, id: "colorOfTheDay", text: "Color of the Day" },
        { content: <GiftRegistryContent />, id: "giftRegistry", text: "Gift Registry" },
        { content: <GalleryContent />, id: "gallery", text: "Gallery" },
    ]

    return (
        <Group direction="row" gap="none" className={styles.container}>
            <Group align="center" wrap="nowrap" justify="space-between" className={styles.left}>
                <Group>
                    <br />
                    <Group className="slideDown" align="center" gap="none">
                        <div className={styles.left__title} onClick={() => navigate('/')}>Juls & Jay</div>
                        <div className={styles.left__date}><Moment format="DD">{tradDate}</Moment> & <Moment format="DD.MM.YYYY">{mainDate}</Moment></div>
                        <h5>🤎🧸</h5>
                    </Group>
                    <br />
                    <br />
                    <Group gap="xs" align="center">
                        {
                            sections.map(({ id, text }) => <div
                                key={id}
                                onClick={() => scrollToSection(id)}
                                className={`transition ${activeSection === id ? styles.active : ''} ${styles.left__menu_link}`}>
                                {text}
                            </div>)
                        }
                    </Group>
                </Group>
                <Group align="center" className={`slideUp ${styles.left__footer}`} gap="none">
                    <div>Juls & Jay wedding</div>
                    <div><Moment format="Do">{tradDate}</Moment> & <Moment format="Do MMMM, YYYY">{mainDate}</Moment>, Jos, Nigeria</div>
                    <div className={styles.left__hr}></div>
                    <b className="gold">#rekindled24</b>
                </Group>
            </Group>
            <div ref={containerRef} className={styles.right}>
                {
                    sections.map(({ id, content }) => <div className={styles.section} ref={(ref) => ref && sectionRefs.current.push({ id, ref })} key={id} id={id}>{content}</div>)
                }
                <StyledFooter className={styles.footer} justify="center" gap="xs" align="center">
                    <p className="font2 gold title">Juls & Jay</p>
                    <div className={styles.left__hr}></div>
                    <p className="font3" style={{ fontSize: 22 }}><Moment format="DD">{tradDate}</Moment> & <Moment format="DD.MM.YYYY">{mainDate}</Moment></p>
                    <h5>🤎🧸</h5>
                </StyledFooter>
                <ScrollToTop smooth />
            </div>
        </Group>
    )
}

const HomeSectionContent = () => {
    return (
        <StyledHome align="center" justify="center" className={styles.home}>
            <Group gap="none" align="center" className="shake">
                <p className={styles.home__content__first}>Juls & Jay</p>
                <Group direction="row" gap="sm" align="center" className={styles.home__content__second}>
                    <span>14th & <Moment format="Do MMMM, YYYY">{mainDate}</Moment></span>
                    <CircleFill size={6} />
                    <span>JOS</span>
                    <CircleFill size={6} /> <span>Nigeria</span></Group>
            </Group>
        </StyledHome>
    )
}

const CoupleSectionContent = () => {

    const renderer = ({ days, hours, minutes, seconds, completed }: CountdownRenderProps) => {
        const padNumber = (n: number) => n < 10 ? `0${n}` : `${n}`
        if (completed) {
            // Render a completed state
            return <Moment fromNow>{mainDate}</Moment>;
        } else {
            // Render a countdown
            return <Group direction="row" className="slideDown" gap="md" wrap="nowrap">
                {
                    days > 0 && <Group gap="none">
                        <p style={{ fontSize: '54px' }} className="font3 fontWhite"><b>{padNumber(days)} :</b></p>
                        <p className="font3 fontWhite">day{days > 1 ? 's' : ''}</p>
                    </Group>
                }
                <Group gap="none">
                    <p style={{ fontSize: '54px' }} className="font3 fontWhite"><b>{padNumber(hours)} :</b></p>
                    <p className="font3 fontWhite">hour{hours > 1 ? 's' : ''}</p>
                </Group>
                <Group gap="none">
                    <p style={{ fontSize: '54px' }} className="font3 fontWhite"><b>{padNumber(minutes)} :</b></p>
                    <p className="font3 fontWhite">minute{minutes > 1 ? 's' : ''}</p>
                </Group>
                <Group gap="none">
                    <p style={{ fontSize: '54px' }} className="font3 fontWhite"><b>{padNumber(seconds)}</b></p>
                    <p className="font3 fontWhite">second{seconds > 1 ? 's' : ''}</p>
                </Group>
            </Group>;
        }
    };

    return (
        <StyledCouple align="center" className={styles.couple}>
            <Group style={{ padding: '30px' }}>
                <br />
                <Group align="center" gap="none">
                    <p className="font2 gold gettingMarried">We are getting married!</p>
                    <p className="gettingMarriedDate font3">on <Moment format="Do">{tradDate}</Moment> & <Moment format="Do MMMM, YYYY">{mainDate}</Moment> — Jos, Nigeria</p>
                </Group>
                <br />
                <Group direction="row" align="center" justify="center">
                    <Group direction="row" align="center" className={styles.couple__card}>
                        <div className={`${styles.bridePictureDetails}`}>
                            <p className={styles.couple__card__name}>
                                Juliana Job 🤎
                            </p>
                            <p className={styles.couple__card__who}>The Bride</p>
                            <p className={styles.couple__card__bio}>
                                Adventurous soul, graceful, kind-hearted, with a beautiful smile illuminating joy.
                            </p>
                            <br />
                            <Group direction="row" gap="xs">
                                <Link to={'https://www.facebook.com/juliana.job2'} target="_blank" className={styles.icon}><Facebook size={16} /></Link>
                                <Link to={'https://www.instagram.com/ms_boww'} target="_blank" className={styles.icon}><Instagram size={16} /></Link>
                                <Link to={'https://www.twitter.com/jobjuliana1'} target="_blank" className={styles.icon}><Twitter size={16} /></Link>
                            </Group>
                        </div>
                        <div className={`bride-picture picture ${styles.picture} ${styles.bridePicture}`}></div>
                    </Group>
                    <Group direction="row" align="center" className={styles.couple__card}>
                        <div className={`groom-picture picture ${styles.picture}`}></div>
                        <Group gap="none">
                            <p className={styles.couple__card__name}>
                                Jay Ademola 🧸
                            </p>
                            <p className={styles.couple__card__who}>The Groom</p>
                            <p className={styles.couple__card__bio}>
                                Compassionate man, strong, adventurous spirit, with a warm smile lighting every moment.
                            </p>
                            <br />
                            <Group direction="row" gap="xs">
                                <Link to={'https://thefunbi.com'} className={styles.icon} target="_blank"><Globe2 size={16} /></Link>
                                <Link to={'https://www.facebook.com/crazielips'} className={styles.icon} target="_blank"><Facebook size={16} /></Link>
                                <Link to={'https://www.instagram.com/crazielips'} target="_blank" className={styles.icon}><Instagram size={16} /></Link>
                                <Link to={'https://www.twitter.com/crazielips'} target="_blank" className={styles.icon}><Twitter size={16} /></Link>
                            </Group>
                        </Group>
                    </Group>
                </Group>
                <br />
            </Group>
            <StyledCountDownContainer justify="center" gap="sm" className={styles.couple__countdown} align="center">
                <Countdown
                    date={mainDate}
                    renderer={renderer}
                />
                <i className="fontWhite font3 slideUp">
                    <b>
                        <Typewriter
                            options={{
                                strings: [`"We can't wait to share our special day with you."`, `Help us capture our wedding with joy.`],
                                autoStart: true,
                                loop: true,
                            }}
                        />
                    </b>
                </i>
            </StyledCountDownContainer>
        </StyledCouple>
    )
}

const OurStorySectionContent = () => {

    return (
        <StyledOurStory className={styles.ourStory}>

            <Group className={styles.ourStory__container}>
                <Group className={`${styles.ourStory__content}`} gap="none">
                    <Group gap="none">
                        <p className="font2 gold" style={{
                            marginBottom: '-10px',
                            fontSize: 30
                        }}>Our Story</p>
                        <div className={styles.left__hr}></div>
                        <p className="font3" style={{
                            letterSpacing: '.6rem',
                            fontWeight: 'bold',
                            textTransform: "uppercase"
                        }}>How <span className="gold"> We </span> Met</p>
                    </Group>
                    <br />
                    <p className="font3">
                        My(Jay) brother thought we'd click. Despite initial silence, I persisted in reaching out, and eventually, we connected. Initially, distance strained our relationship, leading to a breakup. However, fate intervened once more when we unexpectedly found ourselves in the UK, reigniting our connection. Our reunion was marked by a renewed sense of hope, and we vowed to love each other past our differences. Through the challenges and joys of being together, our love deepened, proving that our journey was worth every twist and turn. <b><u>#rekindled24</u></b>
                    </p>
                </Group>
                <div className={styles.ourStory__video}>
                    <iframe src="https://www.youtube.com/embed/p2mvqEpkGPc?si=AhDEjjC0LRyeRFcZ&amp;controls=0&rel=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                </div>
            </Group>
        </StyledOurStory>
    )
}

const CeremonySectionContent = () => {

    return (
        <StyledOurStory className={styles.ceremony}>
            <Group align="center" className={styles.ceremony__container}>
                <Group gap="none" style={{ width: '92%' }}>
                    <p className="font2 gold" style={{
                        marginBottom: '-10px',
                        fontSize: 30
                    }}>Wedding</p>
                    <div className={styles.left__hr}></div>
                    <p className="font3" style={{
                        letterSpacing: '.6rem',
                        fontWeight: 'bold',
                        textTransform: "uppercase"
                    }}>Ce<span className="gold">remo</span>ny</p>
                </Group>

                <Group direction="row" justify="center" align="center" gap="none">
                    <StyledCeremonyCard gap="xs" justify="center" className={`${styles.ceremony__card} first`}>
                        <p className={styles.ceremony__card__number}>01</p>
                        <p className={styles.ceremony__card__title}>Trad</p>
                        <small className={styles.ceremony__card__description}>
                            Our Yoruba traditional marriage will be a vibrant celebration of love and family on the <b className="gold"><Moment format="Do">{tradDate}</Moment></b>.
                            Join us as the groom's family arrives with music and dance, exchanges greetings, and presents gifts. The bride will be unveiled, vows will be exchanged, and blessings will be bestowed upon the happy couple.
                            We'll then end the ceremony with lively music, food, and joy!
                        </small>
                    </StyledCeremonyCard>

                    <StyledCeremonyCard gap="xs" justify="center" className={`${styles.ceremony__card} second`}>
                        <p className={styles.ceremony__card__number}>02</p>
                        <p className={styles.ceremony__card__title}>Joining</p>
                        <small className={styles.ceremony__card__description}>
                            The heart of our celebration arrives as the bride, radiant and blessed by her family, joins the groom on the <b className="gold"><Moment format="Do">{mainDate}</Moment></b>.
                            Witness their exchange of vows, a heartfelt promise for a lifetime together. Rings will be exchanged, symbolizing their unwavering commitment.
                            As elders offer their blessings, feel the love and well wishes surround this newly formed union.                         </small>
                    </StyledCeremonyCard>

                    <StyledCeremonyCard gap="xs" justify="center" className={`${styles.ceremony__card} third`}>
                        <p className={styles.ceremony__card__number}>03</p>
                        <p className={styles.ceremony__card__title}>Reception</p>
                        <small className={styles.ceremony__card__description}>
                            The ceremony's magic continues at the reception!
                            Get ready to indulge in a delicious meal as we share stories, laughter, and well wishes for the happy couple.
                            The dance floor will be open, inviting everyone to join the infectious energy of Yoruba rhythms.
                            Let's raise a toast to love, family, and the beautiful journey that lies ahead for the bride and groom!
                        </small>
                    </StyledCeremonyCard>

                    <StyledCeremonyCard gap="xs" justify="center" className={`${styles.ceremony__card} fourth`}>
                        <p className={styles.ceremony__card__number}>04</p>
                        <p className={styles.ceremony__card__title}>After Party</p>
                        <small className={styles.ceremony__card__description}>
                            The celebration continues into the night with music, dancing, and merriment. Guests toast to our happiness and join in the festivities, creating unforgettable memories to cherish for years to come.
                            Enjoy refreshing drinks, mingle with loved ones, and bust a move on the dance floor to your favorite tunes. It's a chance to let loose, create lasting memories, and send off the newlyweds with unforgettable joy!
                        </small>
                    </StyledCeremonyCard>
                </Group>
            </Group>

            <div className={styles.ceremony__carousel}>
                <Carousel
                    swipeable={false}
                    draggable={false}
                    showDots={true}
                    responsive={responsive}
                    infinite={true}
                    autoPlay={false}
                    autoPlaySpeed={1000}
                    keyBoardControl={true}
                    customTransition="all .5"
                    transitionDuration={500}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                >
                    <div className={styles.ceremony__img}>
                        <img src="/assets/images/programv21.png" alt="Trad" />
                    </div>
                    <div className={styles.ceremony__img}>
                        <img src="/assets/images/programv22.png" alt="Joining" />
                    </div>
                    <div className={styles.ceremony__img}>
                        <img src="/assets/images/programv23.png" alt="Lord we come before thee now hymn" />
                    </div>
                    <div className={styles.ceremony__img}>
                        <img src="/assets/images/programv24.png" alt="To God be the glory hymn" />
                    </div>
                    <div className={styles.ceremony__img}>
                        <img src="/assets/images/programv25.png" alt="Great is thy faithfulness hymn" />
                    </div>
                    <div className={styles.ceremony__img}>
                        <img src="/assets/images/programv26.png" alt="We praise thee o Lord hymn" />
                    </div>
                    <div className={styles.ceremony__img}>
                        <img src="/assets/images/programv27.png" alt="Order of Photography" />
                    </div>
                    <div className={styles.ceremony__img}>
                        <img src="/assets/images/programv28.png" alt="Reception" />
                    </div>
                </Carousel>
            </div>
        </StyledOurStory>
    )
}

const WhereAndWhenContent = () => {

    return (
        <Group className={styles.whenAndWhere}>
            <Group className={styles.whenAndWhere__container}>
                <Group gap="none">
                    <p className="font2 gold" style={{
                        marginBottom: '-10px',
                        fontSize: 30
                    }}>Questions</p>
                    <div className={styles.left__hr}></div>
                    <p className="font3" style={{
                        letterSpacing: '.5rem',
                        fontWeight: 'bold',
                        fontSize: '24px',
                        textTransform: "uppercase"
                    }}>When <span className="gold"> & </span> Where</p>
                </Group>

                <Group direction="row" align="start" gap="md">
                    <StyledWhenAndWhereCard className={styles.whenAndWhere__card} gap="none">
                        <Group justify="center" className="header first">
                            <Group align="center" justify="center" className="content">
                                <iframe title="Crispan Hotel, Jos 930103" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15723.735896688615!2d8.876520148281253!3d9.85590669387461!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x105375502d4c22fb%3A0x2ac7001c3d0f0974!2sCrispan%20Hotel%2C%20Jos.!5e0!3m2!1sen!2suk!4v1711552729366!5m2!1sen!2suk" style={{ border: 0 }} allowFullScreen={false} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </Group>
                        </Group>
                        <Group gap="xs" className={styles.whenAndWhere__content}>
                            <p className={styles.whenAndWhere__title}>Trad</p>
                            <Group direction="row" wrap="nowrap" gap="sm" align="center" className={styles.whenAndWhere__location}>
                                <GeoAlt />
                                <div>Patrick Hall, Crispan Hotel, <br /> Yingi-Rayfield, VV8P+JF <br />Jos, Plateau, Nigeria.
                                </div>
                            </Group>
                            <Group direction="row" gap="sm" align="center" className={styles.whenAndWhere__time}><Calendar2Day /> <Moment format="DD.MM.YYYY">{tradDate}</Moment></Group>
                            <Group direction="row" gap="sm" align="center" className={styles.whenAndWhere__time}><Clock /> <Moment format="hh:mm A">{tradDate}</Moment></Group>
                        </Group>
                    </StyledWhenAndWhereCard>

                    <StyledWhenAndWhereCard className={styles.whenAndWhere__card} gap="none">
                        <Group justify="center" className="header second">
                            <Group align="center" justify="center" className="content">
                                <iframe title="Mees Palace, RWR5+9R3, Jos 930103" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3931.1128305872458!2d8.906970775029055!3d9.84089009025652!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1053743a6c633687%3A0xc6772b2311d0a8d4!2sMees%20Palace!5e0!3m2!1sen!2suk!4v1711550582400!5m2!1sen!2suk" style={{ border: 0 }} allowFullScreen={false} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </Group>
                        </Group>
                        <Group gap="xs" className={styles.whenAndWhere__content}>
                            <p className={styles.whenAndWhere__title}>Joining & Wedding Party</p>
                            <Group direction="row" gap="sm" align="center" className={styles.whenAndWhere__location}>
                                <GeoAlt />
                                <div>
                                    Mees Palace, 930103, RWR5+9R3 <br />Jos, Plateau, Nigeria.
                                </div>
                            </Group>
                            <Group direction="row" gap="sm" align="center" className={styles.whenAndWhere__time}><Calendar2Day /> <Moment format="DD.MM.YYYY">{mainDate}</Moment></Group>
                            <Group direction="row" gap="sm" align="center" className={styles.whenAndWhere__time}><Clock /> <Moment format="HH:mm A">{mainDate}</Moment></Group>
                        </Group>
                        <br />
                    </StyledWhenAndWhereCard>

                    <StyledWhenAndWhereCard className={styles.whenAndWhere__card} gap="none">
                        <Group justify="center" className="header third">
                            <Group align="center" justify="center" className="content">
                                <iframe title="Crispan Hotel, Jos 930103" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15723.735896688615!2d8.876520148281253!3d9.85590669387461!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x105375502d4c22fb%3A0x2ac7001c3d0f0974!2sCrispan%20Hotel%2C%20Jos.!5e0!3m2!1sen!2suk!4v1711552729366!5m2!1sen!2suk" style={{ border: 0 }} allowFullScreen={false} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </Group>
                        </Group>
                        <Group gap="xs" className={styles.whenAndWhere__content}>
                            <p className={styles.whenAndWhere__title}>Accommodation</p>
                            <Group direction="row" gap="sm" align="center" wrap="nowrap" className={styles.whenAndWhere__location}>
                                <Circle />
                                <div>
                                    Hotels and distance from the traditional party.
                                </div>
                            </Group>
                            <Group direction="row" gap="sm" align="center" className={styles.whenAndWhere__time}><Buildings /> Crispan Hotel (0 min)</Group>
                            <Group direction="row" gap="sm" align="center" className={styles.whenAndWhere__time}><Buildings /> Hotel Roniicon (3 min)</Group>
                        </Group>
                        <br />
                    </StyledWhenAndWhereCard>

                </Group>
            </Group>
        </Group >
    )
}

const MenuContent = () => {

    return (
        <Group className={styles.menu}>
            <Group className={styles.menu__container}>
                <Group gap="none" justify="center" align="center">
                    <p className="font2 gold" style={{
                        marginBottom: '-10px',
                        fontSize: 30
                    }}>Menu</p>
                    <div className={styles.left__hr}></div>
                    <p className="font3" style={{
                        letterSpacing: '.5rem',
                        fontWeight: 'bold',
                        fontSize: '24px',
                        textAlign: 'center',
                        textTransform: "uppercase"
                    }}>Trad <span className="gold"> & </span> Reception</p>
                </Group>
                <Group direction="row" justify="center">
                    <div className={styles.menu__img}>
                        <img src="/assets/images/menu-1.png" alt="Trad Wedding Menu" />
                    </div>
                    <div className={styles.menu__img}>
                        <img src="/assets/images/menu-2.png" alt="Reception Wedding Menu" />
                    </div>
                </Group>
            </Group>
        </Group >
    )
}

const GalleryContent = () => {

    interface CustomImage extends Image {
        original: string;
    }

    const images: CustomImage[] = [
        {
            src: "https://files.brandqrcodes.com/resized/605x806/assets/qrAssets/78571b02-070c-47c9-9f46-53dfecdf2862/aa0322b7-0c41-4114-93a8-e0058393a228_27867492.jpeg",
            original: "https://files.brandqrcodes.com/assets/qrAssets/78571b02-070c-47c9-9f46-53dfecdf2862/aa0322b7-0c41-4114-93a8-e0058393a228_27867492.jpeg",
            width: 605,
            height: 806,
            caption: "Court Wedding (Memories - https://brandqrcodes.com/qrs/0xDLgUOJB)",
        },
        {
            src: "https://files.brandqrcodes.com/resized/605x806/assets/qrAssets/78571b02-070c-47c9-9f46-53dfecdf2862/d21f95f1-5347-4ff1-a096-309f5fec0e0a_31164096.jpeg",
            original: "https://files.brandqrcodes.com/assets/qrAssets/78571b02-070c-47c9-9f46-53dfecdf2862/d21f95f1-5347-4ff1-a096-309f5fec0e0a_31164096.jpeg",
            width: 605,
            height: 806,
            tags: [
                { value: "Post Wedding Shoot", title: "Post Wedding Shoot" },
            ],
            caption: "Court Wedding (Memories - https://brandqrcodes.com/qrs/0xDLgUOJB)",
        },
        {
            src: "https://files.brandqrcodes.com/resized/605x806/assets/qrAssets/78571b02-070c-47c9-9f46-53dfecdf2862/1ebf29f7-347c-4fb8-a0d9-18545cfd43e2_26637551.jpeg",
            original: "https://files.brandqrcodes.com/assets/qrAssets/78571b02-070c-47c9-9f46-53dfecdf2862/1ebf29f7-347c-4fb8-a0d9-18545cfd43e2_26637551.jpeg",
            width: 605,
            height: 806,
            tags: [
                { value: "Court Wedding", title: "Court Wedding" },
            ],
            caption: "Court Wedding (Memories - https://brandqrcodes.com/qrs/0xDLgUOJB)",
        },
        {
            src: "https://files.brandqrcodes.com/resized/605x806/assets/qrAssets/78571b02-070c-47c9-9f46-53dfecdf2862/22b2b151-f9f4-43b2-80e1-618596fe1abe_72483380.jpeg",
            original: "https://files.brandqrcodes.com/assets/qrAssets/78571b02-070c-47c9-9f46-53dfecdf2862/22b2b151-f9f4-43b2-80e1-618596fe1abe_72483380.jpeg",
            width: 605,
            height: 806,
            tags: [
                { value: "Post Wedding Shoot", title: "Post Wedding Shoot" },
            ],
            caption: "Court Wedding (Memories - https://brandqrcodes.com/qrs/0xDLgUOJB)",
        },
        {
            src: "https://files.brandqrcodes.com/resized/605x806/assets/qrAssets/78571b02-070c-47c9-9f46-53dfecdf2862/d057082f-a681-4bc7-b55b-2942a124e34a_44273105.jpg",
            original: "https://files.brandqrcodes.com/assets/qrAssets/78571b02-070c-47c9-9f46-53dfecdf2862/d057082f-a681-4bc7-b55b-2942a124e34a_44273105.jpg",
            width: 605,
            height: 806,
            tags: [
                { value: "Post Wedding Shoot", title: "Post Wedding Shoot" },
            ],
            caption: "Court Wedding (Memories - https://brandqrcodes.com/qrs/0xDLgUOJB)",
        },
        {
            src: "https://files.brandqrcodes.com/resized/605x806/assets/qrAssets/78571b02-070c-47c9-9f46-53dfecdf2862/d057082f-a681-4bc7-b55b-2942a124e34a_46740738.jpg",
            original: "https://files.brandqrcodes.com/assets/qrAssets/78571b02-070c-47c9-9f46-53dfecdf2862/d057082f-a681-4bc7-b55b-2942a124e34a_46740738.jpg",
            width: 605,
            height: 806,
            tags: [
                { value: "Post Wedding Shoot", title: "Post Wedding Shoot" },
            ],
            caption: "Court Wedding (Memories - https://brandqrcodes.com/qrs/0xDLgUOJB)",
        },
        {
            src: "https://files.brandqrcodes.com/resized/605x806/assets/qrAssets/78571b02-070c-47c9-9f46-53dfecdf2862/d21f95f1-5347-4ff1-a096-309f5fec0e0a_81781938.jpeg",
            original: "https://files.brandqrcodes.com/assets/qrAssets/78571b02-070c-47c9-9f46-53dfecdf2862/d21f95f1-5347-4ff1-a096-309f5fec0e0a_81781938.jpeg",
            width: 605,
            height: 806,
            tags: [
                { value: "Post Wedding Shoot", title: "Post Wedding Shoot" },
            ],
            caption: "Court Wedding (Memories - https://brandqrcodes.com/qrs/0xDLgUOJB)",
        },
        {
            src: "https://files.brandqrcodes.com/resized/605x806/assets/qrAssets/78571b02-070c-47c9-9f46-53dfecdf2862/d21f95f1-5347-4ff1-a096-309f5fec0e0a_91784993.jpeg",
            original: "https://files.brandqrcodes.com/assets/qrAssets/78571b02-070c-47c9-9f46-53dfecdf2862/d21f95f1-5347-4ff1-a096-309f5fec0e0a_91784993.jpeg",
            width: 605,
            height: 806,
            tags: [
                { value: "Post Wedding Shoot", title: "Post Wedding Shoot" },
            ],
            caption: "Court Wedding (Memories - https://brandqrcodes.com/qrs/0xDLgUOJB)",
        },
        {
            src: "https://files.brandqrcodes.com/resized/605x806/assets/qrAssets/78571b02-070c-47c9-9f46-53dfecdf2862/d21f95f1-5347-4ff1-a096-309f5fec0e0a_50410280.jpeg",
            original: "https://files.brandqrcodes.com/assets/qrAssets/78571b02-070c-47c9-9f46-53dfecdf2862/d21f95f1-5347-4ff1-a096-309f5fec0e0a_50410280.jpeg",
            width: 605,
            height: 806,
            tags: [
                { value: "Post Wedding Shoot", title: "Post Wedding Shoot" },
            ],
            caption: "Court Wedding (Memories - https://brandqrcodes.com/qrs/0xDLgUOJB)",
        },
        {
            src: "https://files.brandqrcodes.com/resized/605x806/assets/qrAssets/78571b02-070c-47c9-9f46-53dfecdf2862/d21f95f1-5347-4ff1-a096-309f5fec0e0a_45209443.jpeg",
            original: "https://files.brandqrcodes.com/assets/qrAssets/78571b02-070c-47c9-9f46-53dfecdf2862/d21f95f1-5347-4ff1-a096-309f5fec0e0a_45209443.jpeg",
            width: 605,
            height: 806,
            tags: [
                { value: "Post Wedding Shoot", title: "Post Wedding Shoot" },
            ],
            caption: "Court Wedding (Memories - https://brandqrcodes.com/qrs/0xDLgUOJB)",
        },
        {
            src: "https://files.brandqrcodes.com/resized/605x806/assets/qrAssets/78571b02-070c-47c9-9f46-53dfecdf2862/d21f95f1-5347-4ff1-a096-309f5fec0e0a_42932137.jpeg",
            original: "https://files.brandqrcodes.com/assets/qrAssets/78571b02-070c-47c9-9f46-53dfecdf2862/d21f95f1-5347-4ff1-a096-309f5fec0e0a_42932137.jpeg",
            width: 605,
            height: 806,
            tags: [
                { value: "Post Wedding Shoot", title: "Post Wedding Shoot" },
            ],
            caption: "Court Wedding (Memories - https://brandqrcodes.com/qrs/0xDLgUOJB)",
        },
        {
            src: "https://files.brandqrcodes.com/resized/808x1005/assets/qrAssets/78571b02-070c-47c9-9f46-53dfecdf2862/ab535369-e7e7-4fa0-8ca0-17efb78b827b_65863943.jpeg",
            original: "https://files.brandqrcodes.com/assets/qrAssets/78571b02-070c-47c9-9f46-53dfecdf2862/ab535369-e7e7-4fa0-8ca0-17efb78b827b_65863943.jpeg",
            width: 808,
            height: 1005,
            tags: [{ value: "Pre-Wedding Shoot", title: "Pre-Wedding Shoot" }],
            caption: "Dinner Date (Memories - https://brandqrcodes.com/qrs/0xDLgUOJB)"
        },
        {
            src: "https://files.brandqrcodes.com/resized/510x660/assets/qrAssets/78571b02-070c-47c9-9f46-53dfecdf2862/ab535369-e7e7-4fa0-8ca0-17efb78b827b_88443879.jpeg",
            original: "https://files.brandqrcodes.com/assets/qrAssets/78571b02-070c-47c9-9f46-53dfecdf2862/ab535369-e7e7-4fa0-8ca0-17efb78b827b_88443879.jpeg",
            width: 510,
            height: 660,
            tags: [{ value: "Pre-Wedding Shoot", title: "Pre-Wedding Shoot" }],
            caption: "Dinner Date (Memories - https://brandqrcodes.com/qrs/0xDLgUOJB)"
        },
        {
            src: "https://files.brandqrcodes.com/resized/510x660/assets/qrAssets/78571b02-070c-47c9-9f46-53dfecdf2862/ab535369-e7e7-4fa0-8ca0-17efb78b827b_8094061.jpeg",
            original: "https://files.brandqrcodes.com/assets/qrAssets/78571b02-070c-47c9-9f46-53dfecdf2862/ab535369-e7e7-4fa0-8ca0-17efb78b827b_8094061.jpeg",
            width: 510,
            height: 660,
            tags: [{ value: "Pre-Wedding Shoot", title: "Pre-Wedding Shoot" }],
            caption: "Dinner Date (Memories - https://brandqrcodes.com/qrs/0xDLgUOJB)"
        },
        {
            src: "https://files.brandqrcodes.com/resized/536x693/assets/qrAssets/78571b02-070c-47c9-9f46-53dfecdf2862/ab535369-e7e7-4fa0-8ca0-17efb78b827b_39058960.jpeg",
            original: "https://files.brandqrcodes.com/assets/qrAssets/78571b02-070c-47c9-9f46-53dfecdf2862/ab535369-e7e7-4fa0-8ca0-17efb78b827b_39058960.jpeg",
            width: 536,
            height: 693,
            tags: [{ value: "Pre-Wedding Shoot", title: "Pre-Wedding Shoot" }],
            caption: "Dinner Date (Memories - https://brandqrcodes.com/qrs/0xDLgUOJB)"
        },
        {
            src: "https://files.brandqrcodes.com/resized/510x660/assets/qrAssets/78571b02-070c-47c9-9f46-53dfecdf2862/ab535369-e7e7-4fa0-8ca0-17efb78b827b_85152871.jpeg",
            original: "https://files.brandqrcodes.com/assets/qrAssets/78571b02-070c-47c9-9f46-53dfecdf2862/ab535369-e7e7-4fa0-8ca0-17efb78b827b_85152871.jpeg",
            width: 510,
            height: 660,
            tags: [{ value: "Pre-Wedding Shoot", title: "Pre-Wedding Shoot" }],
            caption: "Dinner Date (Memories - https://brandqrcodes.com/qrs/0xDLgUOJB)"
        },
        {
            src: "https://files.brandqrcodes.com/assets/qrAssets/78571b02-070c-47c9-9f46-53dfecdf2862/ab535369-e7e7-4fa0-8ca0-17efb78b827b_3353909.jpeg",
            original: "https://files.brandqrcodes.com/assets/qrAssets/78571b02-070c-47c9-9f46-53dfecdf2862/ab535369-e7e7-4fa0-8ca0-17efb78b827b_3353909.jpeg",
            width: 605,
            height: 806,
            tags: [{ value: "Pre-Wedding Shoot", title: "Pre-Wedding Shoot" }],
            caption: "Dinner Date (Memories - https://brandqrcodes.com/qrs/0xDLgUOJB)"
        },
        {
            src: "https://files.brandqrcodes.com/assets/qrAssets/78571b02-070c-47c9-9f46-53dfecdf2862/ab535369-e7e7-4fa0-8ca0-17efb78b827b_14636327.jpeg",
            original: "https://files.brandqrcodes.com/assets/qrAssets/78571b02-070c-47c9-9f46-53dfecdf2862/ab535369-e7e7-4fa0-8ca0-17efb78b827b_14636327.jpeg",
            width: 605,
            height: 806,
            tags: [{ value: "Pre-Wedding Shoot", title: "Pre-Wedding Shoot" }],
            caption: "Dinner Date (Memories - https://brandqrcodes.com/qrs/0xDLgUOJB)"
        },
        {
            src: "https://files.brandqrcodes.com/assets/qrAssets/78571b02-070c-47c9-9f46-53dfecdf2862/ab535369-e7e7-4fa0-8ca0-17efb78b827b_81685180.jpeg",
            original: "https://files.brandqrcodes.com/assets/qrAssets/78571b02-070c-47c9-9f46-53dfecdf2862/ab535369-e7e7-4fa0-8ca0-17efb78b827b_81685180.jpeg",
            width: 605,
            height: 806,
            tags: [{ value: "Pre-Wedding Shoot", title: "Pre-Wedding Shoot" }],
            caption: "Dinner Date (Memories - https://brandqrcodes.com/qrs/0xDLgUOJB)"
        },
        {
            src: "https://files.brandqrcodes.com/assets/qrAssets/78571b02-070c-47c9-9f46-53dfecdf2862/ab535369-e7e7-4fa0-8ca0-17efb78b827b_43558600.jpeg",
            original: "https://files.brandqrcodes.com/assets/qrAssets/78571b02-070c-47c9-9f46-53dfecdf2862/ab535369-e7e7-4fa0-8ca0-17efb78b827b_43558600.jpeg",
            width: 605,
            height: 806,
            tags: [{ value: "Pre-Wedding Shoot", title: "Pre-Wedding Shoot" }],
            caption: "Dinner Date (Memories - https://brandqrcodes.com/qrs/0xDLgUOJB)"
        },
        {
            src: "https://files.brandqrcodes.com/assets/qrAssets/78571b02-070c-47c9-9f46-53dfecdf2862/ab535369-e7e7-4fa0-8ca0-17efb78b827b_60460742.jpeg",
            original: "https://files.brandqrcodes.com/assets/qrAssets/78571b02-070c-47c9-9f46-53dfecdf2862/ab535369-e7e7-4fa0-8ca0-17efb78b827b_60460742.jpeg",
            width: 605,
            height: 806,
            tags: [{ value: "Pre-Wedding Shoot", title: "Pre-Wedding Shoot" }],
            caption: "Dinner Date (Memories - https://brandqrcodes.com/qrs/0xDLgUOJB)"
        },
    ];

    const [index, setIndex] = useState(-1);

    const currentImage = images[index];
    const nextIndex = (index + 1) % images.length;
    const nextImage = images[nextIndex] || currentImage;
    const prevIndex = (index + images.length - 1) % images.length;
    const prevImage = images[prevIndex] || currentImage;

    const handleClick = (index: number, _: CustomImage) => setIndex(index);
    const handleClose = () => setIndex(-1);
    const handleMovePrev = () => setIndex(prevIndex);
    const handleMoveNext = () => setIndex(nextIndex);

    return (
        <Group className={styles.gallery}>
            <Group className={styles.gallery__container}>
                <Group gap="none">
                    <p className="font2 gold" style={{
                        marginBottom: '-10px',
                        fontSize: 30
                    }}>Couple's</p>
                    <div className={styles.left__hr}></div>
                    <p className="font3" style={{
                        letterSpacing: '.5rem',
                        fontWeight: 'bold',
                        fontSize: '24px',
                        textTransform: "uppercase"
                    }}>Ga<span className="gold">lle</span>ry</p>
                </Group>
                <Group gap="xs">
                    <p className={styles.text}>
                        Let's keep the magic alive! We invite you to share your photos and videos from our special day. Simply scan the QR code below with your phone's camera, and you'll be directed to a place to upload your memories.
                    </p>
                    <p className={styles.text}>
                        We can't wait to see the celebration through your eyes and create a beautiful collection of moments to cherish forever.
                    </p>
                </Group>
                <Group direction="row">
                    <Group align="center">
                        <img src="assets/images/memories.png" width={200} height={200} alt="Juls and Jay Paypal QR Code" />
                        <Link target={"_blank"} to="https://brandqrcodes.com/qrs/0xDLgUOJB">
                            <Button text="or Click Here" />
                        </Link>
                    </Group>
                </Group>
                <div>
                    <Gallery
                        images={images}
                        onClick={handleClick}
                        enableImageSelection={false}
                    />
                    {!!currentImage && (
                        <Lightbox
                            mainSrc={currentImage.original}
                            imageTitle={currentImage.caption}
                            mainSrcThumbnail={currentImage.src}
                            nextSrc={nextImage.original}
                            nextSrcThumbnail={nextImage.src}
                            prevSrc={prevImage.original}
                            prevSrcThumbnail={prevImage.src}
                            onCloseRequest={handleClose}
                            onMovePrevRequest={handleMovePrev}
                            onMoveNextRequest={handleMoveNext}
                        />
                    )}
                </div>
            </Group>
        </Group >
    )
}

const GiftRegistryContent = () => {

    const [copyToClipboardState, copyToClipboard] = useCopyToClipboard();
    const [showCopyToClipboardState, setShowCopyToClipboard] = useState(false);

    const [copyToClipboardState2, copyToClipboard2] = useCopyToClipboard();
    const [showCopyToClipboardState2, setShowCopyToClipboard2] = useState(false);

    const clearContent = () => {
        setTimeout(() => setShowCopyToClipboard(false), 3000)
    }
    const clearContent2 = () => {
        setTimeout(() => setShowCopyToClipboard2(false), 3000)
    }
    return (
        <Group className={styles.giftRegistry}>
            <Group className={styles.giftRegistry__container}>
                <Group gap="none">
                    <Group direction="row" align="center" gap="xs">
                        <Gift size={22} className="gold" />
                        <p className="font2 gold" style={{
                            marginBottom: '-10px',
                            fontSize: 30
                        }}> Gift</p>
                    </Group>
                    <div className={styles.left__hr}></div>
                    <p className="font3" style={{
                        letterSpacing: '.5rem',
                        fontWeight: 'bold',
                        fontSize: '24px',
                        textTransform: "uppercase"
                    }}>Reg<span className="gold">ist</span>ry</p>
                </Group>
                <div>

                    <p className={styles.text}>
                        While your presence at our wedding is the most precious gift we could receive, for those who are generously offering a contribution, we've decided to <b>forego</b> a traditional registry and <b>physical gifts</b>.
                    </p>
                    <p className={styles.text}>
                        To help us establish our new life together, we've linked our PayPal and bank account details below.  This allows you to contribute financially if you wish.
                    </p>
                    <br />
                    <Group direction="row" className={styles.giftRegistry__content} align="center">
                        <Group align="center">
                            <img src="assets/images/julsnjayPaypal.png" width={200} height={200} alt="Juls and Jay Paypal QR Code" />
                            <Link target={"_blank"} to="https://paypal.me/jayademola">
                                <Button text="or Click Here" />
                            </Link>
                        </Group>
                        <div className={styles.giftRegistry__demacator}></div>
                        <Group gap="none">
                            <small><b>Bank Transfer Details</b></small>
                            <Group wrap="nowrap" align="center" gap="xs" direction="row">
                                <b className="gold">Boluwatife Job </b>
                                <Copy size={14} className="gold" style={{ cursor: 'pointer' }} onClick={() => { copyToClipboard("Boluwatife Job"); setShowCopyToClipboard(true); setShowCopyToClipboard2(false); clearContent() }} />
                                {
                                    copyToClipboardState.error
                                        ? <small>Unable to copy value: {copyToClipboardState.error.message}</small>
                                        : copyToClipboardState.value && showCopyToClipboardState && <small className="success">Copied!</small>
                                }
                            </Group>
                            <Group gap="xs" direction="row">
                                <small><small>GTB</small></small>
                                <small> - <b><u>0128395159</u></b></small>
                                &nbsp;
                                <Copy size={14} style={{ cursor: 'pointer' }} onClick={() => { copyToClipboard2("0128395159"); setShowCopyToClipboard2(true); setShowCopyToClipboard(false); clearContent2() }} />
                                {
                                    copyToClipboardState2.error
                                        ? <small>Unable to copy value: {copyToClipboardState2.error.message}</small>
                                        : copyToClipboardState2.value && showCopyToClipboardState2 && <small className="success">Copied!</small>
                                }
                            </Group>
                        </Group>
                    </Group>
                    <br />
                    <p className={styles.text}>
                        We can't wait to celebrate with you!
                    </p>
                    <p className={styles.text}>
                        <b>Please note:</b>  This is completely optional, and your love and well wishes are all we truly need.
                    </p>
                </div>
            </Group>
        </Group >
    )
}

const ColorOfTheDayContent = () => {
    return (
        <Group className={styles.colorCode}>
            <Group className={styles.colorCode__container}>
                <Group gap="none" justify="center" align="center">
                    <p className="font2 gold" style={{
                        marginBottom: '-10px',
                        fontSize: 30
                    }}>Color</p>
                    <div className={styles.left__hr}></div>
                    <p className="font3" style={{
                        letterSpacing: '.5rem',
                        fontWeight: 'bold',
                        fontSize: '24px',
                        textTransform: "uppercase"
                    }}>of<span className="gold">the</span>day</p>
                </Group>
                <Group direction="row" justify="center">
                    <div className={styles.colorCode__img}>
                        <img src="/assets/images/colorCode14.png" alt="Trad Wedding Color Code" />
                    </div>
                    <div className={styles.colorCode__img}>
                        <img src="/assets/images/colorCode15.png" alt="Reception Wedding Color Code" />
                    </div>
                </Group>
                <br />
                <br />
                <br />
            </Group>
        </Group >
    )
}