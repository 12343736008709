import { CSSProperties, MouseEvent, ReactNode } from "react";
import styles from "./Spinner.module.scss";

interface ButtonProps {
  text?: string;
  icon?: ReactNode;
  color?: "primary" | "danger" | "success";
  ghost?: boolean;
  disabled?: boolean;
  loading?: boolean;
  style?: CSSProperties;
  type?: "button" | "submit";
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
}

export const Button = (props: ButtonProps) => {
  const {
    text,
    icon,
    disabled = false,
    loading = false,
    ghost = false,
    color = "primary",
    style = {},
    type = "button",
    onClick,
  } = props;

  const classNames = ["btn", color];
  if (ghost) classNames.push("ghost");

  return (
    <button
      className={classNames.join(" ")}
      onClick={onClick}
      disabled={disabled}
      style={style}
      type={type}
    >
      {loading && <Spinner />}
      {!loading && icon && <div className="icon">{icon}</div>}
      {!loading && <div className="text">{text}</div>}
    </button>
  );
};

const Spinner = () => {
  return (
    <div className={styles.ellipsis}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
