import { ErrorBoundary } from "react-error-boundary";
import { HomePage } from "../../pages/HomePage/HomePage";
import { ErrorFallback } from '../../pages/ErrorPage/Errors';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ReactQueryContextProvider } from '../../context/react-query';
import { NotificationContextProvider } from '../../context/notification';

function App() {
  return (
    <BrowserRouter>
      <ErrorBoundary
        FallbackComponent={({ error }) => (
          <ErrorFallback error={error} />
        )}
      >
        <NotificationContextProvider>
          <ReactQueryContextProvider>
            <Routes>
              <Route path="*" element={<HomePage />} />
            </Routes>
          </ReactQueryContextProvider>
        </NotificationContextProvider>
      </ErrorBoundary>
    </BrowserRouter>
  );
}

export default App;
