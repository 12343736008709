import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowLeftIcon } from "../../Icons/ArrowLeft";
import styles from "./Page.module.scss";
import { Item } from "../Item/Item";

interface PageProps {
  backBtn?: boolean;
  actions?: ReactNode;
  children: ReactNode;
}

export const Page = ({ children, actions, backBtn = true }: PageProps) => {
  const navigate = useNavigate();

  const classNames = [styles.container];
  if (!backBtn) classNames.push(styles.noBackBtn);

  return (
    <div className={classNames.join(" ")}>
      {(backBtn || actions) && (
        <div className={styles.top}>
          <div>
            <Item>
              <button className="btn-icon" title="Go Back" onClick={() => navigate(-1)}>
                <ArrowLeftIcon />
              </button>
            </Item>
          </div>

          <div>{actions}</div>
        </div>
      )}

      {children}
    </div>
  );
};
