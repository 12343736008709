import { ReactNode, useMemo } from "react";
import { useNotification } from "../../hooks/use-notification.hook";
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";

const notifyError = {
  type: "error",
  title: "Unexpected error",
  body: "Something went wrong. If the problem persists, please contact IT.",
} as const;

const defaultOptions = {
  queries: {
    refetchOnWindowFocus: false,
    retry: 1,
  },
};

export const ReactQueryContextProvider = ({ children }: { children: ReactNode }) => {
  const { notify } = useNotification();

  const queryClient = useMemo(() => {
    const onError = (e: unknown) => {
      notify(notifyError);
      console.log(e);
    };

    const queryCache = new QueryCache({ onError });
    const mutationCache = new MutationCache({ onError });

    return new QueryClient({ defaultOptions, queryCache, mutationCache });
  }, [notify]);

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};
